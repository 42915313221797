import Description from "components/atoms/Description";
import LazyLoad from "react-lazyload";

const ServiceCard = ({ data, ...props }) => {
  const { inner } = props;
  return (
    <div className={props.className}>
      <div
        className={`flex h-full w-full flex-col items-center justify-start rounded bg-white shadow-[0px_16px_40px_rgba(181,181,181,0.2)] ${
          inner?.className || ""
        }`}
      >
        <div className={`w-full ${data.description ? "mb-4" : ""}`}>
          <div className="mb-4 inline-block">
            <div
              className={
                inner?.iconStyles || "rounded-full bg-gleads-buttonBlue-100 p-3"
              }
            >
              <LazyLoad>{data?.icon}</LazyLoad>
            </div>
          </div>
          <p
            className={`m-0 text-xl font-semibold ${inner?.titleStyles || ""}`}
          >
            {data?.title}
          </p>
        </div>

        {data.description && (
          <Description
            className={`text-sm ${inner?.descriptionStyles || ""}`}
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
