import PropTypes from "prop-types";

/**
 * vendor
 */
/**
 * components
 */
import Title from "components/atoms/Title";
import Description from "components/atoms/Description";
import Img from "components/atoms/Img";
import Button from "components/atoms/Button";
/** ========== */

const PostCard = ({ item, aspect, img, ...props }) => {
  return (
    <div className={props.className}>
      <div className="group flex h-full flex-col justify-between">
        <div className="relative overflow-hidden rounded-t">
          <Img
            image={item?.featuredImage?.node}
            alt={item?.title}
            width={375}
            height={375}
            className={`h-full w-full object-cover ${aspect} transition-all duration-700
            group-hover:scale-110 group-hover:transform`}
            wrapper={{
              className: "w-full relative",
            }}
            {...img}
          />
          <div className="absolute top-0 left-0 z-10 h-full w-full opacity-0 transition-all duration-300 group-hover:opacity-30" />
        </div>
        {/* {item.date && (
          <div className="px-4 pt-4 text-sm font-bold">
            {`${new Date(item.date).getDate()} ${new Intl.DateTimeFormat(
              "en-US",
              {
                month: "short",
              }
            ).format(new Date(item.date))}, ${new Date(
              item.date
            ).getFullYear()}`}
          </div>
        )} */}
        <div className="relative flex flex-1 flex-col justify-between px-4 pt-4 pb-6">
          <div>
            <Title
              as="h3"
              className={`text-xl font-bold leading-relaxed text-gleads-greys-600 transition-colors duration-150 line-clamp-2 hover:text-gleads-oxfordBlue-600 lg:text-gleads-oxfordBlue-700 ${
                item?.excerpt ? "mb-4" : ""
              }`}
            >
              <a href={"/vi" + item.uri}>{item?.title}</a>
            </Title>
            <Description
              className="mb-6 text-ellipsis text-gleads-greys-500 line-clamp-2"
              dangerouslySetInnerHTML={{ __html: item?.excerpt }}
            />
          </div>
          <div className="text-right">
            <Button
              as="a"
              href={"/vi" + item?.uri}
              className={
                props.seeMoreClass ||
                "inline-block w-max rounded-md bg-gleads-greys-100 p-2 text-sm font-bold transition-colors duration-150 hover:bg-gleads-greys-500 hover:text-white"
              }
            >
              Xem thêm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;

PostCard.propTypes = {
  item: PropTypes.object,
  layout: PropTypes.string,
  aspect: PropTypes.string,
  img: PropTypes.object,
};

PostCard.defaultProps = {
  item: {},
  layout: "vertical",
  aspect: "aspect-[16/9]",
  img: {},
};

/** ========== */
