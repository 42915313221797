import PropTypes from "prop-types";

import Img from "components/atoms/Img";
import Title from "components/atoms/Title";
import { cloneElement } from "react";

/**
 *
 * @param {{
 * data: {}
 * button: (<Button/>)
 * }} props
 * @returns JSX.Element
 */
const CatalogItem = ({ data, button, ...props }) => {
  return (
    <>
      <div
        className={
          props.className
            ? props.className
            : "row mb-6 last:mb-0 lg:mb-15 lg:last:mb-0"
        }
      >
        <div
          className={`mb-6 flex items-center justify-center last:mb-0 lg:col-6 lg:mb-0 ${data.image["position"] === "left"
              ? "lg:order-first"
              : " lg:order-last"
            }`}
        >
          <Img
            src={data.image["src"]}
            alt={data.image["alt"]}
            height={data?.image["height"]}
            width={data?.image["width"]}
            className=""
            wrapper={{ className: "flex items-center" }}
          />
        </div>
        <div
          className={`mb-6 flex items-center justify-center last:mb-0 lg:col-6 lg:mb-0 ${data.content["position"] === "left"
              ? "lg:order-first"
              : " lg:order-last "
            }`}
        >
          <div>
            <Title
              as="h3"
              className="mb-6 text-left text-2xl font-bold text-gleads-oxfordBlue-800"
            >
              {data.content["title"]}
            </Title>
            <div
              className={`text-base text-gleads-basic ${button ? "lg:mb-6" : ""
                }`}
              dangerouslySetInnerHTML={{ __html: data.content["description"] }}
            ></div>
            {button && cloneElement(button)}
          </div>
        </div>
      </div>
    </>
  );
};

CatalogItem.propTypes = {
  data: PropTypes.object,
  button: PropTypes.element,
};
CatalogItem.defaultProps = {
  data: {},
  button: null,
};

export default CatalogItem;
