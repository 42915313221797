import { FaFacebookF } from "components/atoms/Icon/fa/FaFacebookF";
import Img from "components/atoms/Img";
/*** Vendors ***/

/*** components ***/

/*** ========== ***/

const listButton = [
  {
    icon: FaFacebookF,
    backgroundColor: "#1877f2",
    url: "https://www.facebook.com/gleadsvn",
  },
  // {
  //   icon: FaLinkedinIn,
  //   backgroundColor: "#0077b5",
  //   url: "/",
  // },
  // {
  //   icon: SiZalo,
  //   backgroundColor: "#1da1f2",
  //   url: "https://zalo.me/536303807395273679",
  // },
  // {
  //   icon: FaYoutube,
  //   backgroundColor: "#FF0000",
  //   url: "/",
  // },
];

/**
 *
 * @param {{
 * }} props Props for the component
 *
 */
const SocialList = ({ ...props }) => {
  return (
    <>
      {listButton.map((item, index) => {
        const TagName = item.icon;
        return (
          <a
            key={index}
            href={item.url}
            rel="nofollow"
            style={{
              backgroundColor: item.backgroundColor,
              width: props.width || "34px",
              height: props.height || "34px",
            }}
            className={
              props.className ||
              "mr-4 inline-flex cursor-pointer rounded-full p-0 align-middle last:mr-0 hover:brightness-110"
            }
            target="blank"
            aria-label={item.url}
          >
            <TagName
              style={{ minWidth: `33px` }}
              width={16}
              height={16}
              size={props.size}
              color="#fff"
              className="m-auto"
            />
          </a>
        );
      })}
      <a
        href={"https://zalo.me/536303807395273679"}
        rel="nofollow"
        style={{
          backgroundColor: "#1da1f2",
          width: props.width || "34px",
          height: props.height || "34px",
        }}
        className={
          props.className ||
          "mr-4 inline-flex cursor-pointer rounded-full p-0 align-middle last:mr-0 hover:brightness-110"
        }
        target="blank"
      >
        <Img
          style={{ minWidth: `33px` }}
          width={16}
          height={16}
          src="/assets/icons/zalo-chat.svg"
          wrapper={{
            className: "w-full h-full flex justify-center items-center",
          }}
          color="#fff"
          className="h-full w-full"
        />
      </a>
    </>
  );
};

export default SocialList;
