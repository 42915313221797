import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Img from "components/atoms/Img";
import menuData from "components/common/Layout/data.json";
import MegaMenu from "components/common/Layout/Header/MenuDesktop/MegaMenu";
import MenuMobile from "components/common/Layout/Header/MenuMobile";

import Button from "components/atoms/Button";

/** ========== */

const Component = () => {
  const [contact, setContact] = useState(null);
  const [logo, setLogo] = useState("logoWhite");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleNavbar = () => {
        const currentNav = document.getElementById("header-nav");
        if (currentNav) {
          if (
            document.body.scrollTop > 40 ||
            document.documentElement.scrollTop > 40
          ) {
            currentNav.style.boxShadow = "0 0 15px 0 rgb(119 119 119 / 30%)";
            currentNav.style.background = "white";
            currentNav.style.color = "#001B52";
            setLogo("logoBlack");
          } else {
            currentNav.style.boxShadow = "none";
            currentNav.style.background = "transparent";
            currentNav.style.color = "white";
            setLogo("logoWhite");
          }
          if (
            document.body.scrollTop > 253 ||
            document.documentElement.scrollTop > 253
          ) {
            setContact(true);
          } else {
            setContact(false);
          }
        }
      };
      handleNavbar();
      window.addEventListener("scroll", handleNavbar);

      return () => {
        /* Clean up function. Remove event before component unmount. */
        window.removeEventListener("scroll", handleNavbar);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <header
      id="gleads-header"
      className="fixed top-0 z-99 w-full bg-transparent text-white transition-all duration-75 lg:h-20 "
    >
      <nav id="header-nav" className={`hidden  lg:block `}>
        <div className="container">
          <div className="row h-20 items-center">
            {/* logo */}
            <div className="lg:col-2">
              <a href={"/vi"} className="block cursor-pointer items-center">
                <Img
                  src={`/assets/common/${logo}.png`}
                  className="h-full w-full"
                  height={logo === "logoBlack" ? 50 : 48}
                  width={logo === "logoBlack" ? 110 : 105}
                  wrapper={{ className: "w-[105px] h-auto" }}
                  alt="logo-gleads"
                  priority={true}
                />
              </a>
            </div>

            {/* menu item  */}

            <div className="col-8 ">
              <ul className="flex items-center justify-between">
                {menuData?.dataMenu.map((menu, index) => {
                  return <MegaMenu item={menu} key={index} />;
                })}
              </ul>
            </div>
            {/* contact */}
            <div className="col-2 text-end">
              {contact && (
                <Button
                  href="/vi/lien-he"
                  variant="outline"
                  btnColor="bg-transparent hover:bg-gleads-buttonBlue-600 hover:text-white "
                  btnTextColor=" text-gleads-buttonBlue-600"
                  as="a"
                >
                  Liên Hệ
                </Button>
              )}
            </div>
          </div>
        </div>
      </nav>
      <MenuMobile />
    </header>
  );
};

export default Component;

Component.propTypes = {
  items: PropTypes.array,
};

Component.defaultProps = {
  items: [],
};

/** ========== */
