import PropTypes from "prop-types";
import styles from "./Index.module.scss";
import { classNames as joinClassNames } from "common/functions";
/**
 *
 * @param {{
 * axis: "x" | "y";
 * }} props Props for the component
 *
 */

function Balloon({ axis, duration, children, ...props }) {
  let animateEl = <></>;
  switch (axis) {
    case "x": {
      animateEl = (
        <BalloonX
          {...props}
          style={{ animationDuration: duration, ...props.style }}
        >
          {children}
        </BalloonX>
      );
      break;
    }
    case "y": {
      animateEl = (
        <BalloonY
          {...props}
          style={{ animationDuration: duration, ...props.style }}
        >
          {children}
        </BalloonY>
      );
      break;
    }
    default:
      break;
  }
  return animateEl;
}
export function BalloonX({ duration, children, ...props }) {
  const { pauseOnMobile, classNames, ...otherProps } = props;

  return (
    <div
      style={{ animationDuration: duration }}
      className={joinClassNames(
        styles["animate-balloon"],
        pauseOnMobile
          ? styles["animate-balloons-X-mobile"]
          : styles["animate-balloons-X"],
        classNames
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export function BalloonY({ duration, children, ...props }) {
  const { pauseOnMobile, classNames, ...otherProps } = props;

  return (
    <div
      style={{ animationDuration: duration }}
      className={joinClassNames(
        styles["animate-balloon"],
        pauseOnMobile
          ? styles["animate-balloons-Y-mobile"]
          : styles["animate-balloons-Y"],
        classNames
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
}
Balloon.propTypes = {
  children: PropTypes.element,
  axis: PropTypes.string,
  duration: PropTypes.string,
};
Balloon.defaultProps = {
  axis: "x",
  duration: `4s`,
};

export default Balloon;
