import Button from "components/atoms/Button";
import CatalogItem from "components/molecules/CatalogItem";

const ServiceCatalog = ({ services }) => {
  return (
    <>
      {services &&
        Array.isArray(services) &&
        services.map((service, idx) => {
          return (
            <CatalogItem
              key={idx}
              data={service}
              button={
                <>
                  {service.content["url"] && (
                    <Button
                      as="a"
                      href={service.content["url"]}
                      rel={service.content["rel"] || ""}
                      target={service.content["target"] || ""}
                      variant="outline"
                      othersClass="text-gleads-buttonBlue-600 cursor-pointer"
                    >
                      Tìm hiểu thêm
                    </Button>
                  )}
                </>
              }
            />
          );
        })}
    </>
  );
};

export default ServiceCatalog;
