import { useState } from "react";
import { useRouter } from "next/router";
import { Transition } from "@headlessui/react";
import style from "./Index.module.scss";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
/*********** */

const MegaMenu = ({ item }) => {
  const [isShowing, setIsShowing] = useState(false);
  const router = useRouter();
  const [dataItem, setdataItem] = useState([]);
  const top = "top-13";
  const hassubmenu = item?.items?.length > 0;

  return (
    <li
      className="relative font-bold"
      onMouseEnter={() => setIsShowing(true)}
      onMouseLeave={() => setIsShowing(false)}
    >
      {item?.url ? (
        <a
          href={item?.url}
          className={` flex items-center  lg:text-sm xl:text-base  ${router.pathname === item?.url ||
            (isShowing && "text-gleads-buttonBlue-600")
            }  font-bold hover:text-gleads-buttonBlue-600  `}
        >
          {item?.name}
          {hassubmenu && (
            <div
              className={`ml-1.5  ${isShowing ? "rotate-180" : "rotate-0 "
                }  font-bold `}
            >
              <FaChevronDown size={10} />
            </div>
          )}
        </a>
      ) : (
        <span
          className={`${isShowing && "text-gleads-buttonBlue-600"
            } flex items-center font-bold hover:text-gleads-buttonBlue-600 lg:text-sm  xl:text-base `}
        >
          {item?.name}
          {hassubmenu && (
            <FaChevronDown
              size={10}
              className={`mt-0.5 ml-2 transform  ${isShowing ? "rotate-180" : "rotate-0"
                }`}
            />
          )}
        </span>
      )}

      {/* MegaMenu */}

      {hassubmenu && (
        <Transition
          show={isShowing}
          enter="transition-all duration-200 ease-in"
          enterFrom="opacity-0 -translate-y-9"
          enterTo="opacity-100 -translate-y-6"
        >
          <div className={`  ${style.sub_menu}  ${top} `}>
            <ul className="relative py-3">
              {item?.items.map((item, index) => {
                return (
                  <li key={index}>
                    {item?.url ? (
                      <a
                        href={item?.url}
                        className={style.listMenu}
                        target={item?.target}
                        rel={item?.linkRelationship}
                      >
                        {item?.name}
                      </a>
                    ) : (
                      <div>
                        <div
                          onMouseEnter={() => setdataItem(item?.items)}
                          onMouseLeave={() => setdataItem([])}
                          className={style.listMenu}
                        >
                          {item?.name}
                          <div className="ml-4">
                            <FaChevronDown size={14} className="-rotate-90" />
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>

            {/* submenu item */}
            {dataItem.length > 0 && (
              <ul
                className="relative border-l border-gleads-greys-500 py-3"
                onMouseEnter={() => setdataItem(dataItem)}
                onMouseLeave={() => setdataItem([])}
              >
                {dataItem?.map((item, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={item?.url}
                        className={style.list_sub_menu}
                        target={item?.target}
                        rel={item?.linkRelationship}
                      >
                        {item?.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </Transition>
      )}
    </li>
  );
};

export default MegaMenu;
