/* eslint-disable @next/next/no-html-link-for-pages */
import Img from "components/atoms/Img";
import Title from "components/atoms/Title";
import dynamic from "node_modules/next/dynamic";
import LazyLoad from "react-lazyload";
import ImageAnimate from "./ImageAnimate";
import styles from "./Index.module.scss";

// const ImageAnimate = dynamic(() => import("./ImageAnimate"), {
//   ssr: false,
// });
/*** Vendors ***/

/*** components ***/

/*** ========== ***/

/**
 *
 * @param {{
 * }} props Props for the component
 *
 */
const Banner = () => {
  return (
    <div className={`${styles.bannerHome}`}>
      <Img
        src={"/assets/images/home/background-home.png"}
        width={1920}
        priority={true}
        height={944}
        className="h-[110%] w-full object-cover lg:h-full"
        wrapper={{
          className: " absolute  lg:block top-0 w-full h-full z-0",
        }}
      />
      <div className="relative">
        <div className="container">
          <Title
            as="h1"
            othersClass="text-left md:text-center mb-6 capitalize md:px-10 pr-10 "
            title={{
              content: "Marketing không khó, để đó Gleads lo!",
              default_word_color: "text-gleads-pink-600",
              highlight_word_list: ["không khó, để đó"],
              highlight_word_colors: "text-white",
            }}
          />
          <p className="mb-6 text-left text-white md:text-center lg:mb-10">
            Giải pháp marketing & truyền thông số hiệu quả và toàn diện cho
            <b> doanh nghiệp Việt</b>
          </p>
          <div className="mb-20 lg:mb-8">
            <a
              href="/vi/lien-he"
              className=" inline-block rounded-md bg-gleads-pink-600 py-4 px-6 font-bold text-gleads-greys-100"
            >
              Liên hệ tư vấn
            </a>
          </div>
        </div>
        <div className="hidden md:block">
          <ImageAnimate />
        </div>
        <div className="block md:hidden">
          <Img
            priority={true}
            src="/assets/images/home/mobile-banner.png"
            className=" mx-auto"
            wrapper={{ className: `block` }}
            width={375}
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
