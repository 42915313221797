import { createElement } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import parse from "html-react-parser";

/** ========== */

const Component = ({ seo }) => {
  const router = useRouter();
  const removeBreak = (str) => {
    let strReplace = str;

    let canonical = str.match(/<link rel="canonical" .*?\/>/g)[0];
    let custom_canonical = canonical.replace(
      /cms\.gleads.vn/gi,
      "gleads.vn/vi"
    );
    return strReplace
      .replace(/\n|\t/g, "")
      .replace(/<link rel="next" .*?\/>/g, "")
      .replace(/https:\/\/cms\.gleads\.vn/gi, `https://gleads.vn/vi`)
      .replace(canonical, custom_canonical);
  };
  const getCustomHead = (str) => {
    if (str && str.length > 0) {
      const getRobots = str.match(/<meta name="robots" .*?\/>/g)[0];
      const getHead = `${str.replace(/\n|\t/g, "").replace(getRobots, "")}`;
      return {
        robots: getRobots || "",
        head: getHead || "",
      };
    } else {
      return {};
    }
  };
  const dataSeo = getCustomHead(seo?.head);

  return (
    <Head>
      {true ? (
        <>
          <link
            rel="alternate"
            href={`${process.env.NEXT_PUBLIC_HOME_URL}${router.asPath}`}
            hrefLang="vn"
          />
          {seo?.head &&
            parse(removeBreak(seo?.head)).map((item, index) =>
              createElement(item?.type, { ...item.props, key: index + 10 })
            )}
        </>
      ) : (
        <>
          {dataSeo?.head &&
            parse(removeBreak(dataSeo.head)).map((item, index) =>
              createElement(item.type, { ...item.props, key: index + 10 })
            )}
          <meta name="robots" content="noindex, nofollow" />
        </>
      )}
    </Head>
  );
};

export default Component;

/** ========== */
