import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import PostCard from "components/molecules/PostCard";
// import NormalSlider from "components/molecules/Slider/Normal";
const NormalSlider = dynamic(
  () => import("components/molecules/Slider/Normal"),
  {
    ssr: false,
  }
);
import styles from "./Index.module.scss";
import LazyLoad from "react-lazyload";
import dynamic from "node_modules/next/dynamic";

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={`translate-y-50 group absolute top-1/2 -right-10 z-50 h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-gleads-greys-100 text-right transition-colors duration-100 hover:bg-gleads-greys-300 hover:text-white xl:-right-13 ${
        className.includes("slick-disabled") ? "hidden" : "flex"
      }`}
      onClick={onClick}
    >
      <FaChevronRight
        size={16}
        className="text-xs text-gleads-greys-300 group-hover:text-white lg:text-lg"
      />
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={`translate-y-50 group absolute top-1/2 -left-10 z-50 h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-gleads-greys-100 text-left transition-colors duration-100 hover:bg-gleads-greys-300 hover:text-white xl:-left-13 ${
        className.includes("slick-disabled") ? "hidden" : "flex"
      }`}
      onClick={onClick}
    >
      <FaChevronLeft
        size={16}
        className="text-xs text-gleads-greys-300 group-hover:text-white lg:text-lg"
      />
    </div>
  );
}

const PostSlider = ({ posts, children, ...props }) => {
  return (
    <LazyLoad>
      {posts && Array.isArray(posts) && posts.length > 0 ? (
        <NormalSlider
          type="normal"
          customClass={styles.sliderCustom}
          wrapper={{
            className: `${styles.containerWrapper} relative`,
          }}
          settings={
            props.settings
              ? props.settings
              : {
                  dots: false,
                  infinite: true,
                  autoplay: false,
                  speed: 500,
                  initialSlide: 0,
                  slidesToShow: posts.length <= 3 ? posts.length : 3,
                  arrows: posts.length <= 3 ? false : true,
                  touchMove: posts.length <= 3 ? false : true,
                  slidesToScroll: 1,
                  variableWidth: false,
                  adaptiveHeight: false,
                  centerMode: true,
                  centerPadding: "15px",
                  responsive: [
                    {
                      breakpoint: 1024,
                      settings: {
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                        dots: false,
                      },
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                        dots: true,
                      },
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                      },
                    },
                  ],
                  nextArrow: <NextArrow />,
                  prevArrow: <PrevArrow />,
                  className: styles.sliderCustom,
                }
          }
          {...props}
        >
          {children
            ? children
            : posts.map((post, idx) => (
                <div key={idx}>
                  <PostCard
                    item={post}
                    className="flex h-full flex-1 flex-col rounded-sm bg-white"
                  />
                </div>
              ))}
        </NormalSlider>
      ) : (
        <></>
      )}
    </LazyLoad>
  );
};

export default PostSlider;
