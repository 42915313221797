import fetchSeo from "common/fetch/fetchSeo";

/*** components ***/

import Footer from "components/common/Layout/Footer";
import Home from "components/templates/trang-chu";
import HeaderHome from "components/common/Layout/HeaderHome";
import Seo from "components/common/Seo";
/*** dataStatic ***/
import dataStatic from "../pages/vi/data.json";
import queryByFetch from "common/fetch/gqlQueryByFetch";

/*** ========== ***/

export default function Homes({ seo, recentPosts }) {
  return (
    <>
      <Seo seo={seo} />
      <HeaderHome />
      <Home dataPost={recentPosts} dataStatic={dataStatic} />
      <Footer />
    </>
  );
}

export const getStaticProps = async () => {
  const uri = "/";

  const seo = await fetchSeo({
    uri: uri,
  });
  const recentPosts = await queryByFetch({
    graphqlQuery: `
      query theQuery {
        posts(first: 9) {
          nodes {
            title
            date
            uri
            excerpt
            featuredImage {
              node {
                sizes(size: THUMBNAIL)
                sourceUrl(size: THUMBNAIL)
                srcSet(size: THUMBNAIL)
                mediaDetails {
                  width
                  height
                  sizes {
                    name
                    sourceUrl
                    width
                    height
                  }
                }
                altText
              }
            }
          }
        }
      }
    `,
  });

  return {
    props: {
      seo: seo || [],
      recentPosts: recentPosts?.data?.posts?.nodes || [],
    },
    revalidate: 1,
  };
};
