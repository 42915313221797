import { classNames } from "common/functions";
import PropTypes from "prop-types";
import { isValidElement } from "react";
import styles from "./Index.module.scss";

/**
 *
 * @param {{
 * as: "div"|"h1"| "h2"|"h3"|"h4"|"h5";
 * title: {content:String, default_word_color:String, highlight_word_colors:Array|String, highlight_word_list:Array};
 * othersClass;
 * className;
 * }} props Props for the component
 *
 */
const Title = ({ title, text, as, id, children, ...props }) => {
  const As = as || "h2";
  const textSize = (as) => {
    let text_size = "";
    switch (as) {
      case "h1": {
        text_size = `${styles.h1}`;
        break;
      }
      case "h2": {
        text_size = `${styles.h2}`;
        break;
      }
      case "h3": {
        text_size = `${styles.h3}`;
        break;
      }
      case "h4": {
        text_size = `${styles.h4}`;
        break;
      }

      default:
        break;
    }
    return text_size;
  };
  const hightLightTitle = (titleProps) => {
    const list_highlight = { ...titleProps }.highlight_word_list || [];
    let clone_title = { ...titleProps }.content || "";
    const isArray_highlight_word_colors = Array.isArray(
      titleProps?.highlight_word_colors
    );
    list_highlight.forEach((word, wordIndx) => {
      const split_word_arr = clone_title.split(word);
      clone_title = split_word_arr.join(
        `<span class="${
          isArray_highlight_word_colors
            ? titleProps?.highlight_word_colors[wordIndx]
            : titleProps?.highlight_word_colors || "text-gleads-pink-600"
        }">${word}</span>`
      );
    });
    return clone_title;
  };
  const generateElementByParse = (string) => {
    return (
      <As
        id={id ? id : null}
        className={
          props.className ||
          classNames(
            styles.title,
            title?.default_word_color || "text-gleads-oxfordBlue-700",
            textSize(as),
            props.othersClass
          )
        }
        dangerouslySetInnerHTML={{ __html: string }}
      />
    );
  };
  if (title) {
    return generateElementByParse(hightLightTitle(title));
  } else {
    if (children?.constructor === String) {
      return generateElementByParse(children);
    } else {
      return (
        <As
          id={id ? id : null}
          {...props}
          className={
            props.className ||
            classNames(styles.title, textSize(as), props.othersClass)
          }
        >
          {children}
        </As>
      );
    }
  }
};

Title.propTypes = {
  text: PropTypes.string,
  as: PropTypes.string,
  id: PropTypes.string,
};

Title.defaultProps = {
  text: null,
  as: "h2",
};
export default Title;
