
/*** Vendors ***/

/*** components ***/

/*** ========== ***/

/**
 *
 * @param {{
 * }} props Props for the component
 *
 */
const ListItem = ({ listData, className }) => {
  return (
    <div className={className}>
      <p className=" mb-4 block font-bold uppercase text-gleads-greys-900 ">
        {listData?.name}
      </p>
      <ul>
        {listData?.items?.map((item, index) => {
          return (
            <li key={index} className="mb-2 last:mb-0">
              {item.url ? (
                <a
                  className="inline-block text-sm hover:text-gleads-oxfordBlue-700"
                  href={item.url}
                  rel={item?.rel || ""}
                  target={item?.target || ""}
                >
                  {item.name}
                </a>
              ) : (
                <span className=" text-sm"> {item.name}</span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ListItem;
