import { Fragment, useState, useRef } from "react";
import { Transition } from "@headlessui/react";
import Img from "components/atoms/Img";


/** ========== */

import dataStatic from "components/common/Layout/data.json";
import Button from "components/atoms/Button";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import useOnClickOutside from "common/hooks/useOnClickOutside";

const DropdownMenu = ({ data, isRoot }) => {
  const [explained, setExplained] = useState(false);

  const renderMenuItem = () => {
    let item = (
      <span
        className={`${isRoot || (data.items && data.items.length > 0)
          ? `text-base font-bold ${explained
            ? "text-gleads-oxfordBlue-700"
            : "text-gleads-oxfordBlue-900"
          }`
          : "text-sm font-normal text-gleads-greys-700"
          }`}
      >
        {data.name}
      </span>
    );
    let explainButton = (
      <div className="inline-flex" onClick={() => setExplained(!explained)}>
        <FiChevronDown
          className={`transform transition-all duration-200 ${explained ? "rotate-180" : "rotate-0"
            }`}
          size={20}
        />
      </div>
    );

    if (data.url) {
      item = (
        <a className="block" href={data.url}>
          {item}
        </a>
      );
    }

    if (!isRoot && !data.items) {
      // Không phải rootNode và không có node con
      return (
        <li className="relative mb-2 flex items-center justify-between">
          {item}
        </li>
      );
    } else {
      // Là rootNode hoặc có các node con
      return (
        <li className="relative mb-4 flex items-center justify-between">
          {item}
          {data.items && data.items.length > 0 && explainButton}
        </li>
      );
    }
  };

  return (
    <>
      {renderMenuItem()}
      {data.items && Array.isArray(data.items) && data.items.length > 0 && (
        <Transition
          as={Fragment}
          show={explained}
          enter="transition-all duration-300 ease-linear"
          enterFrom="transform max-h-0"
          enterTo="transform max-h-[60vh]"
          leave="transition-all duration-300 ease-linear"
          leaveFrom="transform max-h-[60vh]"
          leaveTo="transform max-h-0"
        >
          <ul className="mb-6 pl-10">
            {data.items.map((subItem, idx) => (
              <DropdownMenu key={`sub-menu-${idx}`} data={subItem} />
            ))}
          </ul>
        </Transition>
      )}
    </>
  );
};

const Component = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, setMenuOpened);
  return (
    <nav
      ref={ref}
      style={{ maxHeight: `90%` }}
      className="fixed top-0 left-0 z-99 block w-full overflow-y-auto overflow-x-hidden bg-white text-black shadow-md  lg:hidden"
    >
      <div className={`container py-3 `}>
        <div className="relative">
          <div className={`flex items-center justify-between`}>
            <a href={"/vi"} className="block cursor-pointer items-center">
              <Img
                src={`/assets/common/logoMobile.png`}
                className="h-full w-full"
                height={37}
                width={80}
                wrapper={{ className: "w-20 h-auto" }}
                alt="logo-gleads"
                priority={true}
              />
            </a>
            <button
              onClick={() => setMenuOpened(!menuOpened)}
              className="flex h-7 cursor-pointer flex-col justify-around border-0 bg-transparent p-0 outline-none focus:outline-none"
            >
              <div
                style={{ transformOrigin: "1px" }}
                className={` h-0.5 w-7 rounded-sm bg-black ${menuOpened
                  ? "rotate-45 transform transition-all duration-150 ease-linear "
                  : ""
                  }`}
              />
              <div
                style={{ transformOrigin: "1px" }}
                className={` h-0.5 w-7 rounded-sm  bg-black ${menuOpened
                  ? "translate-x-2  transform opacity-0 transition-all duration-150 ease-linear "
                  : ""
                  }`}
              />
              <div
                style={{ transformOrigin: "1px" }}
                className={`  h-0.5 w-7 rounded-sm bg-black  ${menuOpened
                  ? "-rotate-45 transform transition-all duration-150 ease-linear"
                  : ""
                  }`}
              />
            </button>
          </div>
        </div>
        <Transition
          show={menuOpened}
          className="mt-6 overflow-hidden"
          enter="transition-all duration-300 ease-linear"
          enterFrom="transform max-h-0"
          enterTo="transform max-h-[90vh] opacity-100"
          leave="transition-all duration-200 ease-linear"
          leaveFrom="transform max-h-[90vh] opacity-50"
          leaveTo="transform max-h-0"
        >
          <ul>
            {dataStatic?.dataMenu.map((item, idx) => {
              return (
                <DropdownMenu key={`menu-${idx}`} data={item} isRoot={true} />
              );
            })}
          </ul>
          <div className="text-center">
            <Button
              as="a"
              variant="outline"
              othersClass="text-gleads-buttonBlue-600 px-14"
              href="/vi/lien-he"
            >
              Liên hệ
            </Button>
          </div>
        </Transition>
      </div>
    </nav>
  );
};

export default Component;

/** ========== */
