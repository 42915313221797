import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * vendor
 */

/**
 * components
 */
import Img from "components/atoms/Img";
import Balloon from "components/atoms/Animate/Balloon";
// import FeedbackSlider from "components/organisms/FeedbackSlider";
const FeedbackSlider = dynamic(
  () => import("components/organisms/FeedbackSlider"),
  {
    ssr: false,
  }
);
import LazyLoad from "react-lazyload";
import dynamic from "node_modules/next/dynamic";

/** ========== */

const FeedBackGleads = ({ data }) => {
  const elRef = useRef(null);
  const [parentDiv, setParentDiv] = useState(null);

  const getOffsets = (width, height) => {
    const parentdiv = parentDiv;
    let horizonOffset, verticalOffset;
    if (parentdiv) {
      const offsetHorizonToParentCenter = parseInt(parentdiv.offsetWidth / 2);
      const offsetHorizonToChildCenter = parseInt(width / 2); //Chiều rộng tối đa của childDiv chia đôi
      horizonOffset = offsetHorizonToParentCenter - offsetHorizonToChildCenter;

      const offsetVerticalToParentCenter = parseInt(parentdiv.offsetHeight / 2);
      const offsetVerticalToChildCenter = parseInt(height / 2); //Chiều cao tối đa của childDiv chia đôi
      verticalOffset =
        offsetVerticalToParentCenter - offsetVerticalToChildCenter;
    }

    return { horizontal: horizonOffset, verrtical: verticalOffset };
  };

  const renderBubbleImages = () => {
    var div = 360 / data.length;
    var vRadius = 110;
    var hRadius = 100;

    return (
      <>
        {data.map((item, idx) => {
          const { avatar } = item;
          const y = Math.sin(div * idx * (Math.PI / 200)) * vRadius;
          const x = Math.cos(div * idx * (Math.PI / 190)) * hRadius;

          const { horizontal, verrtical } = getOffsets(
            avatar?.width,
            avatar?.height
          );

          const top = y + verrtical;
          const left = x + horizontal;

          //Math.floor(Math.random() * (max - min + 1) + min)
          const random = Math.floor(Math.random() * (10 - 0 + 1)) + 0;

          return (
            <div
              key={idx}
              style={{
                top: `${top}px`,
                left: `${left}px`,
                width: `${avatar?.width * (avatar?.ratio?.width || 1)}px`,
                height: `${avatar?.height * (avatar?.ratio?.height || 1)}px`,
              }}
              className={`absolute div-bubble-${idx}`}
            >
              <Balloon key={idx} axis="y" duration={`${random + 3}s`}>
                <Img
                  priority={true}
                  src={avatar?.src}
                  width={avatar?.width}
                  height={avatar?.height}
                  className={avatar?.className}
                  wrapper={{ className: "w-full h-full" }}
                />
              </Balloon>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <LazyLoad>
      <div className="row overflow-hidden">
        <div className="lg:col-4">
          <div className="mx-auto mb-10 flex h-80 min-h-full w-80 min-w-full items-center justify-center lg:mb-0">
            <div
              ref={(el) => {
                if (!el) return;
                setParentDiv(el);
              }}
              id="parent-div"
              className="relative h-12 w-12"
            >
              {renderBubbleImages()}
            </div>
          </div>
        </div>

        <div ref={elRef} className="lg:col-8">
          <FeedbackSlider container={elRef} feedbacks={data} />
        </div>
      </div>
    </LazyLoad>
  );
};

export default FeedBackGleads;

FeedBackGleads.propTypes = {
  data: PropTypes.array,
};

FeedBackGleads.defaultProps = {
  data: [],
};

/** ========== */
