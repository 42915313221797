
// Join className String
export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Replace all character string
String.prototype.replaceAll = function (search, replacement) {
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }
  let target = this;
  return target.replace(new RegExp(escapeRegExp(search), "g"), replacement);
};




