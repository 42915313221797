import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail";
import { BiPhone } from "@react-icons/all-files/bi/BiPhone";
import { FiMapPin } from "@react-icons/all-files/fi/FiMapPin";
import Img from "components/atoms/Img";
import dataFooter from "components/common/Layout/data.json";
import SocialList from "components/molecules/SocialList";

import Dropdown from "./Dropdow";
import ListItem from "./ListItem";

export default function Footer() {
  return (
    <footer id="gleads-footer" className="bg-white">
      <div className="container">
        <div className="row py-10 lg:py-13">
          <div className="col-12 lg:col-5">
            <div className="mb-6 flex items-center justify-between lg:mb-4 ">
              <a href={"/vi"} className="block text-center lg:text-left">
                <Img
                  src={"/assets/common/logoFooter.svg"}
                  className="h-full w-full"
                  wrapper={{
                    className: "w-full h-auto lg:m-0 ",
                  }}
                  width={132}
                  height={61}
                  alt="logo-gleads"
                />
              </a>
              <div className="block lg:hidden">
                <SocialList className="mr-2 inline-flex cursor-pointer rounded-full p-0 align-middle last:mr-0 hover:brightness-110" />
              </div>
            </div>
            <p>
              Gleads là công ty tiếp thị kỹ thuật số hàng đầu tại Việt Nam, với
              đội ngũ chuyên viên 10 năm kinh nghiệm, cung cấp các dịch vụ SEO,
              PPC, Brand Identity, Website Design...
            </p>
            <b className="mb-4 block">
              <a
                href="/vi/lien-he"
                rel="nofollow"
                className="text-gleads-greys-900 hover:text-gleads-oxfordBlue-700"
              >
                LIÊN HỆ
              </a>
            </b>
            <div className="mb-3 flex items-center">
              <div className="mr-3">
                <AiOutlineMail size={20} color="#2EAFEF" />
              </div>
              <a
                href="mailto:service@gleads.vn"
                rel="nofollow"
                className="hover:text-gleads-oxfordBlue-700"
              >
                service@gleads.vn
              </a>
            </div>
            <div className="mb-3 flex items-center">
              <div className="mr-3">
                <BiPhone size={20} color="#2EAFEF" />
              </div>
              <a
                href="tel:+8428 7305 4898"
                rel="nofollow"
                className=" hover:text-gleads-oxfordBlue-700"
              >
                +8428 7305 4898
              </a>
            </div>
            <div className="mb-6 flex ">
              <div className="mr-3 mt-0.5">
                <FiMapPin size={20} color="#2EAFEF" />
              </div>

              <a
                href="https://goo.gl/maps/VXRmUHmF6AmWbped7"
                className="hover:text-gleads-oxfordBlue-700"
                target={"_blank"}
                rel="nofollow noreferrer"
              >
                39-41 đường D4, Khu Him Lam, Phường Tân Hưng, Quận 7, Thành Phố
                Hồ Chí Minh
              </a>
            </div>
          </div>
          <div className="lg:col-1"></div>
          <div className="col-12 hidden lg:col-3 lg:block ">
            <ListItem className="mb-6" listData={dataFooter.footer[0]} />
          </div>

          <div className="col-12 hidden lg:col-3 lg:block">
            <ListItem className="mb-6" listData={dataFooter.footer[1]} />
            <ListItem className="mb-6" listData={dataFooter.footer[2]} />
            <ListItem className="mb-6" listData={dataFooter.footer[3]} />
            <div className=" hidden items-center lg:block">
              <b className="mr-6  tracking-normal">Kết nối với chúng tôi</b>
              <div className="mt-4">
                <SocialList className="mr-2 inline-flex cursor-pointer rounded-full p-0 align-middle last:mr-0 hover:brightness-110" />
              </div>
            </div>
          </div>
          <div className="col-12 block lg:hidden">
            <Dropdown data={dataFooter.footer} />
          </div>
          <div className="mt-2 border-t border-t-gleads-greys-500 pt-6 text-center lg:mt-6 lg:text-lg">
            <b className="block">Công ty TNHH Gleads</b> Giấy chứng nhận ĐKKD số
            0314736707 do Sở Kế hoạch và Đầu tư TP.HCM cấp ngày 15.11.2017
          </div>
        </div>
      </div>
    </footer>
  );
}
