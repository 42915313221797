import { Fragment, useRef } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";


export default function AccordionBBC({ data, content }) {
  // make accordions close when one is opened.
  // const AccordionRefs = useRef([]); // store accordion buttons as Refs
  // const [currentAccordion, setCurrentAccordion] = useState(0); // set the current
  function closeCurrent(e) {
    // const button = e.target.closest("button"); // get the button
    // const buttonParent = button?.parentElement?.parentElement; // get the buttons parent (<dt> tag here)
    // const parent = buttonParent.parentElement; // get the buttons parent parent (Disclosure as div here)
    // const index = Array.prototype.indexOf.call(parent.children, buttonParent); // find the index of the button in container
    // for (let i = 0; i < AccordionRefs.current.length; i++) {
    //   // loop throug
    //   if (
    //     AccordionRefs.current[i]?.querySelector("button") &&
    //     AccordionRefs.current[i]
    //       .querySelector("button")
    //       .getAttribute("aria-expanded") === "true" &&
    //     currentAccordion !== index // if it is opened and not the current
    //   ) {
    //     AccordionRefs.current[i].querySelector("button").click(); // then trigger a click to close it.
    //   }
    // }
    // setCurrentAccordion(index); // and set the current
  }
  return (
    <div>
      {data?.length > 0 &&
        data?.map((item, index) => {
          if (
            item.items &&
            Array.isArray(item.items) &&
            item.items.length > 0
          ) {
            return (
              <Disclosure key={index} as="div" className="mb-4 ">
                {({ open }) => (
                  <>
                    <div
                      onClick={closeCurrent}
                    // ref={(el) => (AccordionRefs.current[index] = el)}
                    >
                      <Disclosure.Button className=" flex w-full cursor-pointer items-center justify-between">
                        <b className="block uppercase text-gleads-greys-900">
                          {item.name}
                        </b>

                        <FiChevronDown
                          className={`transition-all duration-75 ease-linear  ${open ? "rotate-180" : "rotate-0"
                            } `}
                          size={20}
                        />
                      </Disclosure.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition-all duration-300"
                      enterFrom="transform max-h-0 opacity-0"
                      enterTo="transform max-h-[400px] opacity-100"
                      leave="transition-all duration-200 "
                      leaveFrom="transform max-h-[400px] opacity-50"
                      leaveTo="transform max-h-0 opacity-0"
                    >
                      <ul className="mb-4 mt-2 pl-4">
                        {item?.items?.map((content, index) => (
                          <li key={index} className="mb-2 last:mb-0">
                            <a
                              href={content?.url}
                              className={`text-sm ${content?.url
                                ? "hover:text-gleads-oxfordBlue-700"
                                : ""
                                }`}
                            >
                              {content?.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </Transition>
                  </>
                )}
              </Disclosure>
            );
          } else {
            return (
              <b key={index} className="mb-4 block">
                <a
                  className="uppercase hover:text-gleads-oxfordBlue-700"
                  href={item.url}
                >
                  {item.name}
                </a>
              </b>
            );
          }
        })}
    </div>
  );
}
