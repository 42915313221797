import Balloon from "components/atoms/Animate/Balloon";
import Img from "components/atoms/Img";
import LazyLoad from "react-lazyload";
import styles from "./Index.module.scss";

/**
 *
 * @param {{
 * }} props Props for the component
 *
 */

function getRndInteger(min, max, r) {
  let numbers = []; // new empty array

  let n, p;
  for (let i = 0; i < r; i++) {
    do {
      n = Math.floor(Math.random() * (max - min + 1)) + min;
      p = numbers.includes(n);
      if (!p) {
        numbers.push(n);
      }
    } while (p);
  }
  return numbers;
}

const dataAnimate = [
  {
    position: 1,
    name: "megaphone",
  },
  {
    position: 20,
    name: "click",
  },
  {
    position: 41,
    name: "movie",
  },
  {
    position: 8,
    name: "pen",
  },
  {
    position: 29,
    name: "light",
  },
  {
    position: 48,
    name: "doc",
  },
];

const ImageAnimate = () => {
  return (
    <div className={`${styles.bannerImageArea} relative `}>
      <Img
        priority={true}
        src="/assets/images/home/banner-small.png"
        className="h-full w-full"
        wrapper={{ className: `${styles.imgBanner}` }}
        width={653}
        height={564}
      />
      <div className={`absolute ${styles.transparentElement}`}>
        <div className="grid h-full w-full grid-cols-10 grid-rows-6 gap-0 ">
          {Array(60)
            .fill(0)
            .map((item, index) => {
              const iconAnimate = dataAnimate.find(
                (item) => item.position === index
              );
              if (iconAnimate) {
                return (
                  <Balloon
                    key={`balloon-${index}`}
                    axis="y"
                    duration={`${getRndInteger(4, 10, 6)[0]}s`}
                    classNames={`${styles.iconAnimate}`}
                    pauseOnMobile={true}
                  >
                    <Img
                      src={`/assets/images/home/${iconAnimate.name}.svg`}
                      width={170}
                      height={170}
                      className={`${styles.icon}`}
                    />
                  </Balloon>
                );
              } else {
                return <div key={index}></div>;
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default ImageAnimate;
