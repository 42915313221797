
/*** Vendors ***/

/*** components ***/
import ServiceCard from "components/molecules/ServiceCard";

/*** ========== ***/

/**
 *
 * @param {{
 * }} props Props for the component
 *
 */
const GleadsSubjects = ({ data, ...props }) => {
  const itemPerPage = data.length / 2;
  const firstList = data.slice(0, itemPerPage);
  const secoundList = data.slice(itemPerPage, data.length);

  return (
    <>
      <div className={props.colClassName}>
        {firstList.map((item, idx) => (
          <ServiceCard
            key={`card-1-${idx}`}
            data={item}
            className="mb-6"
            inner={{
              className: "text-center px-5 py-10 h-56",
              titleStyles: "text-gleads-oxfordBlue-800 lg:text-base xl:text-xl",
              descriptionStyles: "",
            }}
          />
        ))}
      </div>
      <div className={props.colClassName}>
        {secoundList.map((item, idx) => (
          <ServiceCard
            key={`card-2-${idx}`}
            data={item}
            className="mb-6 lg:mt-6 lg:last:mb-0"
            inner={{
              className: "text-center px-5 py-10 h-56",
              titleStyles: "text-gleads-oxfordBlue-800 lg:text-base xl:text-xl",
              descriptionStyles: "",
            }}
          />
        ))}
      </div>
    </>
  );
};

export default GleadsSubjects;
